import ClickCommunity from "./ClickCommunity/ClickCommunity";


export default function Community() {
  return (
    <div>
      <ClickCommunity
      />
    </div>
  )
}
